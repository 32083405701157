.datepicker-full-width .react-datepicker__input-container {
  width: 100%;
}
 .datepicker-full-width .react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__time-list {
  -webkit-padding-start: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}
